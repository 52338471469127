<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head :title="(isNew) ? title : item.user.name" back="Organisation.Conventions">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid || model.used" icon="save" text="Save" v-on:click="onSaveClick" />
		<app-content-head-actions-item v-if="!isNew && !isFounder && !isSelf" :disabled="isNew" :danger="true" icon="delete" text="Delete" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-body :loading="is.loading && is.initialised">

		<app-content-box>

			<app-input-text label="Name" :textPrefix="$organisation.name" v-model="model.name" :validation="$v.model.name" placeholder="Enter convention specific name (e.g. 2023)" />
			<app-input-date label="Starts" v-model="model.from" :validation="$v.model.from" />
			<app-input-date label="Ends" v-model="model.to" :validation="$v.model.to" />

		</app-content-box>

		<app-content-box>

			<app-input-toggle label="Registration" v-model="model.platform.registration" :validation="$v.model.platform.registration" />
			<app-input-toggle label="Schedule" v-model="model.platform.schedule" :validation="$v.model.platform.schedule" />
			<app-input-toggle label="Checkout" v-model="model.platform.checkout" :validation="$v.model.platform.checkout" />
			<app-input-toggle label="Library" v-model="model.platform.library" :validation="$v.model.platform.library" />

		</app-content-box>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'
import { required } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			model: {
				id: false,
				from: 0,
				to: 0,
				name: '',
				platform: {
					registration: 0,
					schedule: 0,
					checkout: 0,
					library: 0
				}
			}
		}

	},

	validations: {
		model: {
			name: {
				required
			},
			from: {
				required
			},
			to: {
				required
			},
			platform: {}
		}
	},

	methods: {

		onSaved: function() {

			this.$router.push({
				name: 'Organisation.Conventions',
				params: this.$route.params
			})

		}

	}

}

</script>

<style scoped>

</style>